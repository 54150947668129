* {
    padding: 0;
    border: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
}

.mp3-page {     
    flex-direction: column;
     
}

.top-section {
    display: flex;
    flex-direction: column;
    align-items: center;    
    background-color: white;
}

.top-section-image {
    padding: 20px;
}

.top-section-guide {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

/* Search bar */

.search-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;  
    justify-content: center;
}


  
#search {
    width: 520px;
    height: 52px;    
    box-sizing: border-box;
    padding-left: 20px;
    border: 1px solid black;
    border-radius: 8px 0px 0px 8px;   
    flex: 1; 
    
}
  
.search-container-button {
    background-color: #FF0102; 
    height: 52px; 
    color: #FFFFFF; 
    border: none; 
    padding: 10px 20px; 
    cursor: pointer; 
    border-radius: 0px 8px 8px 0px;
    flex-shrink: 0;
}

.convert-again-button {
    background-color: #FF0102; 
    height: 52px; 
    width: 152px; 
    color: #FFFFFF; 
    border: none; 
    padding: 10px 20px; 
    cursor: pointer; 
    margin-right: 10px;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0;
}




@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
.convert-again-button:hover {
    background-color: darkred; /* Change color on hover if needed */
}
 
.search-container-button:hover {
    background-color: darkred; /* Change color on hover if needed */
}

.top-section-promo-text {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: #231A1A;
    font-family: "Inter", sans-serif;
    padding: 90px 0px 50px 0px;
    text-align: center;
}
.top-section-promo-text-title {            
    font-weight: 700;
    font-size: 24px;    
}    




.top-section-guide {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;    
    color: #231A1A;
    grid-gap: 0px 50px;
    padding: 0px 186px 55px 186px;
    align-content: space-around;
    align-items: baseline;
}

.top-section-guide-element {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;    
    width: 322px;
}

.top-section-guide-element-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    
}
.top-section-guide-element-text {    
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

}

/* Bottom section */

.bottom-section {
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: black;
}

.bottom-section-upper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 60px 0px;
    align-items: center;
}

.bottom-section-upper-title {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFCFC;
}

.bottom-section-upper-text {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #C2C2C2;   
    max-width: 622px;
}


.bottom-section-benefits-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(182px, 1fr));
    align-content: space-around;
    justify-content: space-around;
    align-items: baseline;
    justify-items: center;

}

.bottom-section-benefits-element {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 18px;
    color: #FFFCFC;
    max-height: 160px;
}

.bottom-section-benefits-element-bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.bottom-section-benefits-element-title {    
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;

}

.bottom-section-benefits-element-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;


}

.spacer{
    height: 100px;
}

.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Added to allow wrapping on smaller screens */
    justify-content: center;
    align-items: center;       
    padding: 20px; /* Added padding for better spacing */    
}
.footer-element {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #FFFCFC;
}

.element {
    position: relative;
    padding: 20px;
    margin: 10px 0;    
}

.element::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 4px; 
    height: 4px; 
    background-color: red;
    border-radius: 50%;
}

.element:not(:last-child)::after {
    /* Keep the dot for all elements except the last */
    content: "";
}

.element:last-child::after {
    /* Remove the dot for the last element */
    content: none;
}



/* Responsive Design */

@media (max-width: 1024px) {
    .top-section-guide {
        grid-template-columns: 1fr 1fr;
        padding: 0px 60px 55px 60px;
    }

    .top-section-guide-element {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .top-section-guide {
        grid-template-columns: 1fr;
        padding: 0px 30px 55px 30px;
    }

    .top-section-guide-element {
        width: 100%;
        padding: 0 10px;
    }
}

@media (max-width: 480px) {
    .top-section-guide {
        padding: 0px 15px 55px 15px;
    }

    .top-section-guide-element-title, .top-section-guide-element-text {
        font-size: 14px;
    }
}

/* Footer responsivnes */
@media (max-width: 768px) {
    .footer {
        flex-direction: column; /* Change layout to column on smaller screens */
    }

    .element::after {
        top: 100%; /* Move dot below each element */
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .element:not(:last-child)::after {
        /* Move the dot below each element except the last one */
        content: "";
        width: 4px; 
        height: 4px;
    }

    .element:last-child::after {
        /* Remove the dot for the last element */
        content: none;
    }
}

/* search seaction responsivnes*/ 

@media (max-width: 768px) {
    .search-container {
        flex-direction: column;
        text-align: center;
    }   

    #search {
        width: 70vw;
        height: 50px !important;
        padding: 15px 0px;
        border-radius: 8px; /* Uniform border radius */
    }
    .search-container-button {
        width: fit-content;
        border-radius: 8px; /* Give both elements the same radius for uniformity */
    }

    .search-container-button {
        margin-top: 10px; /* Add spacing between input and button on small screens */
    }
}


/* benefits responsivnes */ 

@media (max-width: 1024px) {
    .bottom-section-benefits-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .bottom-section-benefits-container {
        grid-template-columns: 1fr;
        grid-gap: 15px; 
    }
}

